import { Headers, Http, BaseRequestOptions } from '@angular/http';

const AUTH_HEADER_KEY = 'Authorization';
const AUTH_PREFIX = 'Bearer';

export class AuthRequestOptions extends BaseRequestOptions {
  
  constructor() {
    super();
    
    const authModel = JSON.parse(localStorage.getItem('AUTH_MODEL'));
    if(authModel) {
      this.headers.append(AUTH_HEADER_KEY, `${AUTH_PREFIX} ${authModel.token}`);
    }
  }

}