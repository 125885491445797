import {Component, OnInit} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-error',
  templateUrl: 'error.component.html'
})

export class ErrorComponent {

}
