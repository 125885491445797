import { Component, HostBinding } from '@angular/core';
import { MatchHeightModule } from '../directives/match-height.directive';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    // Variables
    currentDate: Date = new Date();
  private loadScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    })
  }

  onClickTop() {
    window.scrollTo(0, 0);
  }

}
