import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  {path: '/hotel', title: 'Hotéis', icon: 'fa fa-bed warning', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  {path: '/aereo', title: 'Passagens', icon: 'icon-plane warning', class: '',
    badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  {
    path: '', title: 'Outros serviços', icon: 'ft-award warning', class: 'has-sub',
     badge: '+', badgeClass: 'text-bold-700 warning px-2', isExternalLink: false,
    submenu: [
      {path: '/outros/pacotes', title: 'Pacotes', icon: 'soap-icon-beach warning', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/outros/carros', title: 'Aluguel de Carros', icon: 'soap-icon-car-1 warning', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/outros/seguros', title: 'Seguros de Viagem', icon: 'fa fa-umbrella warning', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: 'https://www.partner.viator.com/pt/67593', title: 'Transfers/Atividades', icon: 'fa fa-bus warning', class: '',
        badge: '', badgeClass: '', isExternalLink: true, submenu: []},
      {path: 'https://latinatravel.minhaviagem.com.br/', title: 'Ingressos', icon: 'fa fa-ticket-alt warning', class: '',
        badge: '', badgeClass: '', isExternalLink: true, submenu: []},
      {path: '/home', title: 'Cruzeiros', icon: 'soap-icon-cruise-3 warning', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},

    ]
  },
  {
    path: '', title: 'Informações', icon: 'ft-info warning', class: 'has-sub',
     badge: '+', badgeClass: 'text-bold-700 warning px-2', isExternalLink: false,
    submenu: [
      {path: '/info/termos', title: 'Termos de Serviço', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/info/privacidade', title: 'Política de Privacidade', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/info/contatos', title: 'Contatos', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/info/faq', title: 'Perguntas Frequentes', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    ]
  },
 {
    path: '', title: 'Conta', icon: 'ft-user warning', class: 'has-sub',
     badge: '+', badgeClass: 'text-bold-700 warning px-2', isExternalLink: false,
    submenu: [
      {path: '/conta/entra', title: 'Entrar na Conta', icon: 'ft-log-in warning', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/conta/cria', title: 'Criar Conta', icon: 'ft-user-plus warning', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/reserva/lista', title: 'Reservas', icon: 'fa fa-calendar-check warning', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/cliente/perfil', title: 'Perfil', icon: 'fa ft-user warning', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/conta/sai', title: 'Sair', icon: 'ft-power warning', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    ]
  },
];
