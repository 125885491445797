import {Injectable, OnInit} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {Http, Response, Headers} from '@angular/http';
import * as jwt_decode from 'jwt-decode';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import {User} from '../models/users';
import {Auth0} from '../models/auth0';
import {AlertService} from './alert.service';

import {AuthService, SocialUser, FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';

@Injectable()
export class Auth0Service implements CanActivate {
  public authModel: Auth0;
  public socialUser: SocialUser;
  public loggedIn: boolean;

  private url = '';
  private headers = new Headers({'Content-Type': 'application/json', 'Accept': 'q=0.8;application/json;q=0.9'});

  constructor(private router: Router, private http: Http,
    private alertService: AlertService, private authService: AuthService) {
    this.authModel = new Auth0;
    if (window.location.hostname === 'www.latinatravel.com.br') {
      this.url = 'https://www.latinatravel.com.br/'
    } else {
      this.url = 'https://www.latinatravel.net/';
    }
  }

  canActivate() {
    if (!this.authModel.token) {
      const auth = JSON.parse(localStorage.getItem('AUTH_MODEL'));
      if (auth) {
        this.authModel = auth;
      }
    }
    if (this.authModel.token !== '') {
      return true;
    }
    this.router.navigate(['/conta/entra']);
    return false;
  }

  getPrevToken(): any {
    return this.authModel;
  }

  getToken(): any {
    if (!this.authModel.token) {
      const auth = JSON.parse(localStorage.getItem('AUTH_MODEL'));
      if (auth) {
        this.authModel = auth;
      }
    }
    if (this.authModel.token && this.authModel.provider === 'LatinaTravel') {
      if (this.isTokenExpired()) {
        this.logout();
      }
      return this.authModel;
    }
    // login social
    this.authService.authState.subscribe((socialUser) => {
      // console.log('socialUser', socialUser);
      if (socialUser != null) {
        this.authModel.token = socialUser.authToken;
        if (socialUser.firstName || !socialUser.name) {
          this.authModel.firstname = socialUser.firstName;
        } else {
          this.authModel.firstname = socialUser.name.split(' ')[0];
        }
        this.authModel.id = socialUser.id;
        this.authModel.idtoken = socialUser.idToken;
        this.authModel.photo = socialUser.photoUrl;
        this.authModel.provider = socialUser.provider;
        this.setToken(this.authModel);
      } else {
        this.authModel.token = '';
        this.authModel.firstname = '';
        this.authModel.id = '';
        this.authModel.idtoken = '';
        this.authModel.photo = '';
        this.authModel.provider = '';
        this.setToken(this.authModel);
      }
    });
    return this.authModel;
  }

  setToken(setModel?: any): void {
    if (setModel) {
      this.authModel = setModel;
      localStorage.setItem('AUTH_MODEL', JSON.stringify(setModel));
    }
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) {return null};

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(authModel?: any): boolean {
    if (!this.authModel.token) {return true};
    if (this.authModel.provider === 'FACEBOOK' || this.authModel.provider === 'GOOGLE') {return false};
    const date = this.getTokenExpirationDate(this.authModel.token);
    if (date === undefined) {return false};
    return !(date.valueOf() > new Date().valueOf());
  }

  logout(): any {
    this.authService.signOut();
    this.authModel.token = '';
    this.authModel.firstname = '';
    this.authModel.id = '';
    this.authModel.idtoken = '';
    this.authModel.photo = '';
    this.authModel.provider = '';
    this.setToken(this.authModel);
    return true;
  }

  login(user: User): Observable<any> {
    return this.http
      .post(this.url + 'v1/api/auth/login', JSON.stringify(user), {headers: this.headers})
      .map(response => {
        const body = response.json();
        if (body.codigo === 'OK') {
          this.setToken(body.conteudo);
          this.alertService.success(body.mensagem, true);
          return body;
        }
        this.alertService.error(body.mensagem);
        return body;
      })
      .catch((error: any) => {
        this.alertService.error(JSON.stringify(error.json()));
        return Observable.throw(false);
      });
  }

  create(user: User): Observable<any> {
    return this.http
      .post(this.url + 'v1/api/auth/register', JSON.stringify(user), {headers: this.headers})
      .map(response => {
        const body = response.json();
        if (body.codigo === 'OK') {
          this.alertService.success(body.mensagem, true);
          return body;
        }
        this.alertService.error(body.mensagem);
        return body;
      })
      .catch((error: any) => {
        this.alertService.error(JSON.stringify(error.json()));
        return Observable.throw(false);
      });
  }

  recover(user: User): Observable<any> {
    return this.http
      .post(this.url + 'v1/api/auth/recover', JSON.stringify(user), {headers: this.headers})
      .map(response => {
        const body = response.json();
        if (body.codigo === 'OK') {
          this.alertService.success(body.mensagem, true);
          return body;
        }
        this.alertService.error(body.mensagem);
        return body;
      })
      .catch((error: any) => {
        this.alertService.error(JSON.stringify(error.json()));
        return Observable.throw(false);
      });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
}
