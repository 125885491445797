import { Component, OnInit, ElementRef, Inject, Renderer2, AfterViewInit, ViewChild } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit, AfterViewInit {
    @ViewChild('sidebarBgImage') sidebarBgImage: ElementRef;
    @ViewChild('appSidebar') appSidebar: ElementRef;
    @ViewChild('wrapper') wrapper: ElementRef;

    options = {
        direction: 'ltr',
        bgColor: 'white',
        bgImage: ''
    };
    hideSidebar: boolean;
    iscollapsed = true;
    isSidebar_sm = false;
    isSidebar_lg = false;
    bgColor = 'white';
    bgImage = '';

    public config: any = {};


    constructor(private elementRef: ElementRef, private configService: ConfigService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2) {

    }

    ngOnInit() {
      this.config = this.configService.templateConf;
      this.bgColor = this.config.layout.sidebar.backgroundColor
      this.bgImage = '';
      setTimeout(() => {
        this.isSidebar_sm = false;
        this.isSidebar_lg = false;
        this.iscollapsed = this.config.layout.sidebar.collapsed;
      }, 0);



    }

    ngAfterViewInit() {
      setTimeout(() => {
        if (this.config.layout.dir) {
          this.options.direction = this.config.layout.dir;
        }
      }, 0);

    }

    toggleHideSidebar($event: boolean): void {
        setTimeout(() => {
            this.hideSidebar = $event;
        }, 0);
    }

    getOptions($event): void {
        this.options = $event;
    }

}
