import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './routes/app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { LogLayoutComponent } from './layouts/log/log-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, RequestOptions } from '@angular/http';
import { AgmCoreModule } from '@agm/core';
import { ErrorComponent } from './error/error.component';
import { AlertComponent } from './alert/alert.component';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

import { CookieService } from 'ngx-cookie-service';
import { Auth0Service } from './services/auth0.service';
import { AuthRequestOptions } from './services/auth-request';
import { AuthErrorHandler } from './services/auth-error-handler';
import { AlertService } from './services/alert.service';
import { ReservaService } from './services/reserva.service';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('234424058350-aeleu0tfrmivtoc814lv3478ehn0npt5.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('1645708615727175')
  }
]);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    LogLayoutComponent,
    ContentLayoutComponent,
    ErrorComponent,
    AlertComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    HttpModule,
    NgbModule.forRoot(),
    AgmCoreModule.forRoot({apiKey: 'AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo'}),
    SocialLoginModule.initialize(config),
    PerfectScrollbarModule,

  ],
  providers: [CookieService,
    { provide: RequestOptions, useClass: AuthRequestOptions },
    { provide: ErrorHandler, useClass: AuthErrorHandler },
    Auth0Service,
    AlertService,
    ReservaService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
