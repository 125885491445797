import {Injectable, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

import {ListReservaRQ} from '../models/listreservarq';
import {AlertService} from './alert.service';

@Injectable()
export class ReservaService {
  public listReservaRQModel: any;
  private headers: any;
  private url = '';

  constructor(private http: Http,
    private alertService: AlertService) {
    this.listReservaRQModel = new ListReservaRQ;
    if (window.location.hostname === 'www.latinatravel.com.br') {
      this.url = 'https://www.latinatravel.com.br/'
    } else {
      this.url = 'https://www.latinatravel.net/';
    }
  }

  reservaReq(RQModel = ''): Observable<any> {
    //console.log('service listReservaRQModel', this.listReservaRQModel)
    if (RQModel) {
      this.listReservaRQModel = RQModel;
    }
    return this.listReservaRQModel;
  }

  reservaAllServ(param = 'list', listReservaRQModel?: ListReservaRQ): Observable<any> {
    const auth = JSON.parse(localStorage.getItem('AUTH_MODEL'));
    if ( !( auth || auth.token || this.listReservaRQModel.origem === '/hotel/final' || this.listReservaRQModel.origem === '/aereo') ) {
      return Observable.of([]);
    }
    this.headers = new Headers({'Content-Type': 'application/json', 'Accept': 'q=0.8;application/json;q=0.9', 'Authorization': localStorage.getItem('AUTH_MODEL')});
    if (listReservaRQModel) {
      this.listReservaRQModel = listReservaRQModel
    }
    return this.http
      .post(this.url + 'v1/api/book/' + param, JSON.stringify(this.listReservaRQModel), {headers: this.headers})
      .map(response => {
        const body = response.json();
        if (body.codigo === "OK") {
          this.alertService.success(body.mensagem, false);
        } else {
          if (body.codigo === "WARNING") {
            this.alertService.warning(body.mensagem, false);
          } else {
            this.alertService.error(body.mensagem, false);
          }
        }
        return body;
      })
      .catch((error: any) => {
        this.alertService.error(JSON.stringify(error));
        return error;
      });
  }

}
